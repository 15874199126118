<template>
  <error-template
    img-url="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/error-pages/500.svg"
    :title="$t('pages.error.500.title')"
    :sub-title="$t('pages.error.500.subtitle')"
  />
</template>

<script>
import ErrorTemplate from './components/error-template'
export default {
  name: 'Page500',
  components: { ErrorTemplate },
  props: {
    error: {
      type: Error,
      default: undefined
    }
  },
  created() {
    if (this.error) {
      this.$logException(this.error)
    }
  }
}
</script>

<style scoped></style>
